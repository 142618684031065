import * as React from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import { useApp } from './context';

import { useWebAnalytics } from '~/utils/webAnalytics';
import DTELogo from 'public/icons/dte.svg';
import MenuIcon from 'public/icons/generic/menu.svg';
import CloseIcon from 'public/icons/generic/x.svg';
import FacebookIcon from 'public/icons/social/facebook.svg';
import LinkedInIcon from 'public/icons/social/linkedin.svg';
import TwitterIcon from 'public/icons/social/twitter.svg';
import YouTubeIcon from 'public/icons/social/youtube.svg';
import PwlyLogoIcon from 'public/icons/pwly-primary-white.svg';
import AnalyzeIcon from 'public/icons/nav/analyze.svg';
import HistoryIcon from 'public/icons/nav/history.svg';
import SimulatorIcon from 'public/icons/nav/simulator.svg';
import EnergyIcon from 'public/icons/nav/energy_usage.svg';
import TourIcon from 'public/icons/nav/tour.svg';
import DteIcon from 'public/icons/nav/launch_dte.svg';
import LocationIcon from 'public/icons/nav/change_site.svg';
import LogoutIcon from 'public/icons/nav/logout.svg';
import Arrow from 'public/icons/nav/arrow.svg';
import { HeaderSiteSwitcher } from '~/components/site-switcher';
import {componentsJson} from "../constants/components";
import { signOut } from 'next-auth/react';
// import { portalStorage, siteStorage } from '~/lib/sessions';

interface HeaderProps {
  setNavOpen: (newVal: boolean) => void;
}

interface NavigationProps extends HeaderProps {
  navOpen: boolean;
}

function useQuickLinks() {
  const { fuelType } = useApp();
  const router = useRouter();

  const {startDate, endDate} = router?.query


  let energyUsageUrl = '/energyusagedata';

  if(startDate){
    energyUsageUrl += `?startDate=${startDate}&endDate=${endDate}`;
  }

  return [
    {
      label: 'Analyze My Bill',
      url: '/',
      external: false,
      id: 'nav-analyze',
      icon: (
        <AnalyzeIcon height={24} width={24} className="mr-4 flex-shrink-0" />
      ),
    },
    {
      label: 'Bill History',
      url: '/bill-history',
      external: false,
      id: 'nav-history',
      icon: (
        <HistoryIcon height={24} width={24} className="mr-4 flex-shrink-0" />
      ),
    },
    {
      label: 'Bill Simulator',
      url: '/bill-simulator',
      external: false,
      id: 'nav-simulator',
      icon: (
        <SimulatorIcon height={24} width={24} className="mr-4 flex-shrink-0" />
      ),
    },
    {
      label: 'Energy Usage Data',
      url: energyUsageUrl,
      external: false,
      id: 'nav-energy',
      icon: (
        <EnergyIcon height={24} width={24} className="mr-4 flex-shrink-0" />
      ),
    },
  ].filter(link => {
    if (fuelType == 'gas') {
      return link.id !== 'nav-energy';
    }
    return true;
  });
}

const DTEHeader: React.VFC<HeaderProps> = ({ setNavOpen }) => {
  const router = useRouter();
  const webAnalytics = useWebAnalytics();
  const quickLinks = useQuickLinks();

    const {dteNavText, dteNavText2, dteNavText3, dteNavText4 } = componentsJson.BillManagement.DTENav

  return (
    <div>
      <div className="bg-header px-6 pb-3 pt-4-safe lg:py-8 lg:px-10">
        <div className="flex w-full items-start justify-between">
          <button
            onClick={() => setNavOpen(true)}
            className="h-8 w-8 lg:hidden"
            type="button"
          >
            <span className="sr-only">{dteNavText}</span>
            <MenuIcon className="text-white" />
          </button>
          <div className="lg:grid lg:w-full lg:grid-cols-3 lg:items-center">
            <div className="lg:order-2 lg:text-center">
              <Link legacyBehavior href="/">
                <a>
                      {/*<DTELogo className="mx-auto h-6 text-white lg:inline-block" />*/}
                  <div className='relative overflow-hidden rounded-xl h-6'>
                    <DTELogo className='w-full h-full text-white object-contain group-hover:scale-125 group-hover:rotate-3 duration-500'/>
                  </div>
                </a>
              </Link>
            </div>
            <div className="lg:flex lg:items-center">
              <span className="block pt-3.5 text-lg text-white lg:pt-0">
                {dteNavText2} {' '}
                <div className="hidden lg:inline-block">{dteNavText3} </div>
              </span>

              <div className="hidden lg:block">
                <HeaderSiteSwitcher />
              </div>
            </div>

            <div className="hidden text-base font-semibold text-white lg:order-3 lg:flex lg:justify-end lg:space-x-4">
              <button
                type="button"
                className="px-6 text-lg font-semibold"
                onClick={async () => {
                  void webAnalytics({
                    EventTag: 'header',
                    EventType: 'signout',
                  });
                  await signOut({ callbackUrl: window.location.origin+'/logout' });
                  // `${process.env.NEXT_PUBLIC_AZURE_URL}/logout?post_logout_redirect_uri=${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/logout`;
                }}
              >
                {dteNavText4} {' '}
              </button>
            </div>
          </div>
          <div className="h-8 w-8 lg:hidden" />
        </div>
      </div>
      <nav className="hidden md:block md:bg-offWhite-200">
        <div className="md:mx-auto md:flex md:max-w-[864px] md:justify-between md:bg-off-offWhite-200">
          {quickLinks.map(link => {
            const isActive = router.asPath === link.url;
            const linkStyle = clsx(
              'text-center text-base leading-none font-semibold py-4 px-6 w-[200px]',
              isActive ? 'text-utility-primary' : 'text-black/60'
            );

            return link.external ? (
              <a
                key={link.label}
                className={linkStyle}
                href={link.url}
                target="_blank"
                rel="noreferrer nofollow noopener"
                id={link.id}
              >
                {link.label}
              </a>
            ) : (
              <Link legacyBehavior key={link.label} href={link.url}>
                <a className={linkStyle} id={link.id}>
                  {link.label}
                </a>
              </Link>
            );
          })}
        </div>
      </nav>
    </div>
  );
};

const makeName = (name: string | undefined) => {
  if (!name) return '';
  return name.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const DTENavigation: React.VFC<NavigationProps> = ({ navOpen, setNavOpen }) => {
  const router = useRouter();
  const { customerSites, customerSiteId, name, setHideIntro } = useApp();
  const [showSiteSwitcher, setShowSiteSwitcher] = React.useState(false);
  const quickLinks = useQuickLinks();
  const webAnalytics = useWebAnalytics();

  const currentSite = customerSites.find(
    site => site.customerSiteId === customerSiteId
  );

  const fullName = makeName(name);

  const {dteNavText5, dteNavText6, dteNavText7, dteNavText8, dteNavText9 } = componentsJson.BillManagement.DTENav

  const links = [
    {
      label: dteNavText5,
      icon: <TourIcon height={24} width={24} className="mr-4 flex-shrink-0" />,
      click: () => {
        setNavOpen(false);
        setHideIntro(false);
      },
    },
    // { label: 'DTE Insight App', url: '#', icon: <InsightIcon height={24} width={24} className="mr-4" /> },
    {
      label: dteNavText6.label,
      url: dteNavText6.value,
      icon: <DteIcon height={24} width={24} className="mr-4 flex-shrink-0" />,
    },
    {
      label: dteNavText7,
      click: async () => {
        await Promise.allSettled([
          webAnalytics({ EventTag: 'header', EventType: 'signout' }),
          //signOut({ callbackUrl: `${window.location.origin}/logout` }),
        ]);
        window.location.href = `${process.env.NEXT_PUBLIC_AZURE_URL}/logout?post_logout_redirect_uri=${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/logout`;
      },
      icon: <LogoutIcon height={24} className="mr-4 flex-shrink-0" />,
    },
  ];

  React.useEffect(() => {
    const handleRouteChange = () => {
      setNavOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, setNavOpen]);

  return (
    <Transition show={navOpen} as={React.Fragment}>
      <Dialog static onClose={() => setNavOpen(false)} open={navOpen}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed top-0 left-0 z-10 min-h-screen w-full bg-black bg-opacity-70" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300 transform-gpu"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="ease-in duration-200 transform-gpu"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="fixed top-0 left-0 z-50 flex h-full w-3/4 flex-col overflow-auto bg-white p-5 sm:w-2/4">
            <div className="px-3 pb-7 pt-2-safe">
              <button
                type="button"
                className="h-6 w-6"
                onClick={() => setNavOpen(false)}
              >
                <span className="sr-only">{dteNavText8}</span>
                <CloseIcon height={29} className="text-gray-600" />
              </button>
            </div>
            <nav className="flex flex-col justify-between">
              {/* Quick Links */}
              {quickLinks.map(link => {
                return (
                  <div className="flex-grow" key={link.label}>
                    <Link legacyBehavior href={link.url}>
                      <a
                        target={link.external ? '_blank' : ''}
                        className={clsx(
                          'mb-7 flex w-full items-center px-6 pt-4 pb-5 text-lg font-medium',
                          router.asPath === link.url && !showSiteSwitcher
                            ? 'nav-bar-active-link'
                            : 'nav-bar-inactive'
                        )}
                      >
                        {link.icon}
                        <span>{link.label}</span>
                      </a>
                    </Link>
                  </div>
                );
              })}
              {/* Site Switch */}
              {customerSites.length > 1 ? (
                <div className="flex-grow">
                  <div
                    className={
                      showSiteSwitcher
                        ? 'nav-bar-active-link'
                        : 'nav-bar-inactive'
                    }
                  >
                    <button
                      type="button"
                      className="mb-7 flex w-full items-center justify-between px-6 pt-4 pb-5 text-lg font-medium"
                      onClick={() => setShowSiteSwitcher(o => !o)}
                    >
                      <div className="flex">
                        <LocationIcon className="mr-4 h-6" />
                        <span>{dteNavText9}</span>
                      </div>
                      {showSiteSwitcher ? (
                        <Arrow height={24} />
                      ) : (
                        <Arrow height={24} className="rotate-180" />
                      )}
                    </button>
                  </div>
                  <AnimatePresence initial={false}>
                    {showSiteSwitcher ? (
                      <motion.ul
                        className="overflow-hidden"
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        exit={{ height: 0 }}
                      >
                        {customerSites.map(site => (
                          <li key={site.customerSiteId}>
                            <button
                              type="button"
                              onClick={async () => {
                                await Promise.all([
                                  webAnalytics({
                                    EventTag: 'header',
                                    EventType: 'switchsites',
                                  }),
                                  fetch('/api/change-site', {
                                    method: 'post',
                                    body: JSON.stringify({
                                      customerSiteId: site.customerSiteId,
                                    }),
                                    headers: {
                                      'Content-Type': 'application/json',
                                    },
                                  }),
                                ]);
                                if (router.pathname === '/') {
                                  void router.reload();
                                } else {
                                  void router.replace('/bill-history');
                                }
                              }}
                              className="flex w-full items-center space-x-2.5 px-6 py-3 pl-18 text-lg text-utility-accent"
                            >
                              <span
                                className={
                                  site.address == currentSite?.address
                                    ? 'border-b border-utility-accent'
                                    : ''
                                }
                              >
                                {site.address}
                              </span>
                            </button>
                          </li>
                        ))}
                      </motion.ul>
                    ) : null}
                  </AnimatePresence>
                </div>
              ) : null}
              {/* More Links */}
              {links.map(link => {
                const isActive = 'url' in link && router.asPath === link.url;
                const className = clsx(
                  'mb-7 flex w-full items-center px-6 pt-4 pb-5 text-lg font-medium',
                  isActive && !showSiteSwitcher
                    ? 'nav-bar-active-link'
                    : 'nav-bar-inactive'
                );
                return (
                  <div className="flex-grow" key={link.label}>
                    {link.click ? (
                      <button
                        type="button"
                        className={className}
                        onClick={link.click}
                      >
                        {link.icon}
                        <span>{link.label}</span>
                      </button>
                    ) : (
                      <Link href={link.url}>
                        <a className={className}>
                          {link.icon}
                          <span>{link.label}</span>
                        </a>
                      </Link>
                    )}
                  </div>
                );
              })}
              {fullName ? (
                <div>
                  <span className="px-6 font-light">{fullName}</span>
                </div>
              ) : null}
            </nav>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

const DTEFooter: React.VFC = () => {

  const {dteNavText10, dteNavText11, dteNavText12, dteNavText13, dteNavText14, dteNavText15, dteNavText16, dteNavText17, dteNavText18} = componentsJson.BillManagement.DTENav
  return (
      <footer className="bg-utility-primary pt-10 pb-15 text-white md:py-10">
        <div className='relative overflow-hidden rounded-xl h-6'>
          <DTELogo className='w-full h-full text-white object-contain group-hover:scale-125 group-hover:rotate-3 duration-500'/>
        </div>
        <ul className="flex justify-center py-8">
          <li>
            <a
                target="_blank"
                rel="noreferrer nofollow noopener"
                href={dteNavText10.value}
            >
              <span className="sr-only">{dteNavText10.label}</span>
              <TwitterIcon className="h-12 w-12" />
            </a>
          </li>
          <li>
            <a
                target="_blank"
                rel="noreferrer nofollow noopener"
                href={dteNavText11.value}
            >
              <span className="sr-only">{dteNavText11.label}</span>
              <FacebookIcon className="h-12 w-12" />
            </a>
          </li>
          <li>
            <a
                target="_blank"
                rel="noreferrer nofollow noopener"
                href={dteNavText12.value}
            >
              <span className="sr-only">{dteNavText12.label}</span>
              <LinkedInIcon className="h-12 w-12" />
            </a>
          </li>
          <li>
            <a
                target="_blank"
                rel="noreferrer nofollow noopener"
                href={dteNavText13.value}
            >
              <span className="sr-only">{dteNavText13.label}</span>
              <YouTubeIcon className="h-12 w-12" />
            </a>
          </li>
        </ul>
        <ul className="space-y-4 pb-7 text-center text-sm md:flex md:items-center md:justify-center md:space-y-0 md:divide-x md:divide-gray-800">
          <li className="md:px-4">
            <a
                className="betterhover:hover:text-utility-accent"
                href={`${process.env.NEXT_PUBLIC_DOMAIN}${dteNavText14.value}`}
            >
              {dteNavText14.label}
            </a>
          </li>
          <li className="md:px-4">
            <a
                className="betterhover:hover:text-utility-accent"
                href={`${process.env.NEXT_PUBLIC_DOMAIN}${dteNavText15.value}`}
            >
              {dteNavText15.label}
            </a>
          </li>
          <li className="md:px-4">
            <a
                className="betterhover:hover:text-utility-accent"
                href={`${process.env.NEXT_PUBLIC_DOMAIN}${dteNavText16.value}`}
            >
              {dteNavText16.label}
            </a>
          </li>
        </ul>

        <span className="block text-center text-xs">
      &copy; {new Date().getFullYear()} {dteNavText17}
    </span>
        <div className="-my-4 flex items-center justify-center text-center text-xs">
          <span>{dteNavText18} {" "}</span>
          <PwlyLogoIcon className="h-16 w-16 px-1" />
        </div>
      </footer>
  );
}

export { DTEHeader, DTENavigation, DTEFooter };
